.custom-text-input {
    position: relative;
    // &:not(.hide-shadow) {
    //     input,
    //     select {
    //         box-shadow: 0px 3px 12px #706e6e29;
    //     }
    // }
    input,
    select {
        padding: 8px 15px 8px 15px;
        border-radius: 8px;
        border: 1px solid #E2E2E2;
        background: #fff;
        height: 42px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        &::placeholder {
            color: #A5A5A5;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
        }
    }
    select {
        padding-right: 30px !important;
    }
    input {
        &[type="date"] {
            padding-right: 12px !important;
        }
    }
    &:not(.hide-default-icon) {
        input {
            padding-left: 47px;
            padding-right: 47px;
            &::placeholder {
                color: #2e2e30;
            }
            &[type="email"] {
                background: url("../../assets/images/envelop-icon.png") no-repeat 20px center #fff;
            }
            &[type="password"] {
                background: url("../../assets/images/lock-icon.png") no-repeat 20px center #fff;
            }
        }
    }
    textarea.form-control {
        min-height: 256px;
        resize: none;
        background-color: #eef9ff;
        border-color: #eef9ff;
        &::placeholder {
            color: #A5A5A5;
        }
    }

    .input-icon {
        position: absolute;
        top: 50%; /* Position from the middle vertically */
        transform: translateY(-50%); /* Adjust for centering */
        right: 12px;
    }
    .password-icon {
        top: 55%;
        right: 8px;
    }
}
