.customer-management{

  .filter-tab{
    width: 83px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #438D40;
    cursor: pointer;
    font-family: "SourceSansOpen600";
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.1428571492433548px;
    border-right-width: 0;
  }
  .active-tab {
    background: linear-gradient(0deg, #58AF54 0%, #91C153 199.41%);
    color: #ffffff;
  }
  .filter-first-tab {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .filter-last-tab{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-right-width: 1px;
  }
  .filters-parent-con {
    display: flex;
  }
  .plan-filter-con{
    margin-right: 21px;
  }
  .status-filter-con{
    margin-bottom: 0px;
  }
  .header-con {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
  }
  
  .plan-boxes {
    padding: 4px 8px;
    border-radius: 6px;
    width: fit-content;
  }
  .plan-free {
    background: #DCF3FA;
    color:  #28B2DC;
  }
  .plan-premium {
    background: #FFECCC;
    color: #FAA41A;
  }

  @media (max-width: 1300px) {
    .filters-parent-con {
      flex-direction: column;
    }
    .status-filter-con{
      margin-bottom: 20px;
    }
    .plan-filter-con{
      .form-label {
        margin-right: 21px !important;
      }
    }
   
  }
  @media (max-width: 700px) {
    .header-con {
     flex-direction: column;
     align-items: inherit;
    }
    .search-input-main-con {
      width: 100%;
    }
    .filters-parent-con {
      align-items: center;
    }
    .status-filter-con{
      margin-right: 0px !important;
    }
    .plan-filter-con{
        margin-right: 0px !important;
    }
  
  }

}  

.customer-details {
  .profile-bio-con {
    display: flex;
    box-shadow: 2px 10px 10px 0px #0000000A;
    border-radius: 12px;
  }
  .profile-img-con {
    width: 20%;
    display: flex;
    align-items: baseline;
    justify-content: center;
    .profile-img {
      width: 150px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .profile-bio-typo {
    width: 80%;
  }

  .primary-color{
    color: #438D40;
  }
  .profile-details-con {
    box-shadow: 2px 10px 10px 0px #0000000A;
    border-radius: 8px;
    .details-inner-con {
      background: #EDF4ED;
      padding: 20px 10px 20px 10px;
      border-radius: 8px;
    }
  }
  @media (max-width: 1400px) {
    .profile-img-con {
      align-items: center;
    }
  }
  @media (max-width: 770px) {
   .profile-bio-con {
    flex-direction: column;
    align-items: center;
   }
   .profile-img-con {
    width: 100%;
   }
   .profile-bio-typo {
    width: 100%;
   }
   
  }
}