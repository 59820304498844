
.app-data-table {
    thead {
        color: #fff;
     
        th {
          
            background: #F7FAFC;
            border: 1px solid #F7FAFC;
          

            // Commented these because not needed in the current project

            // &:first-child {
            //     border-top-left-radius: 4px;
            //     border-bottom-left-radius: 4px;
            // }
            // &:last-child {
            //     border-top-right-radius: 4px;
            //     border-bottom-right-radius: 4px;
            // }
            font-weight: normal;
          
        }
      
    }
    tbody {

        // Commented these because not needed in the current project
        // This makes the rows bigger
                
        // tr {
        //     transition: all 0.3s;
        //     &:hover {
        //         box-shadow: -7px 0px 30px #00000029;
        //     }
        // }

        tr *{
            background-color: transparent;
            color: #40393A;
        }
        td{
            border-bottom: 1px solid #d8dadb52;
            vertical-align: middle;
        }
    }
}
.data-table-head {
    display: flex;
    align-items: center;
    flex-direction: row;

  

    &.sortable {
        user-select: none;
        cursor: pointer;
    }

    & i {
        margin-left: 1px;
        font-size: 15px;
        line-height: inherit;
        &.bx-sort {
            opacity: 0.5;
        }
    }

    & .filter-value {
        font-size: 10px;
        margin-left: 3px;
        font-style: italic;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.table-min-height {
    min-height: 350px;
}

.table-loader-container {
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.column-filter-menu {
    font-size: 20px;
    cursor: pointer;
}

.column-filter-item {
    padding-top: 6px;
    padding-bottom: 6px;
    label,
    input {
        cursor: pointer;
    }
}

.column-filter-item:not(:last-child) {
    border-bottom: 1px solid #eee;
}

span.column-title{
    font-family: "SourceSansOpen600";
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.15714286267757416px;
    color: #444444;
  }