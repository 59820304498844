/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 76px;
  height: 32px;
  overflow: hidden;
  font-family: "SourceSansOpen400";
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0px;
  margin-bottom: 0px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 4px;
  right: 0;
  bottom: 0;
  background-color: #eee;
  transition: .4s;
  border: 1px solid #D0E7CF;
  border-radius: 50px;
  padding: 5px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  border-radius: 50px;
  left: 5 px;
  bottom: 4px;
  transition: .4s;
  background: #A5A5A5;
}

input:checked + .slider {
  background-color: white;
}


input:checked + .slider:before {
  transform: translateX(40px);
  background: linear-gradient(163.5deg, #58AF54 30.68%, #91C153 132.45%);
}

.switch .text {
  position: absolute;
  top: 50%;
  pointer-events: none;
  transform: translateY(-50%);
  transition: .4s;
}

.switch .text.on {
  left: 15px;
  transform: translateX(-45px) translateY(-50%);
}

.switch .text.off {
  color: #999;
  right: 8px;
}

input:checked ~ .text.off {
  transform: translateX(3rem) translateY(-50%);
}

input:checked ~ .text.on {
  transform: translateX(0) translateY(-50%);
}