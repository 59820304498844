.rating-bar-con {
  width: 79px;
  height: 6px;
  border-radius: 8px;
  color: #000;
  background-color: #f1f1f1;
}
.rating-bar-inner {
  height: 6px;
  border-radius: 8px;
  background: #FAA41A !important;
  position: relative;
}
.rating-bar-img {
  position: absolute;
  left: 95%;
  top: -4px;
}