.bls-feedback {
  .stat-boxes-con {
    border-radius: 8px;
    .stat-box-heading {
      font-size: 72px;
      font-weight: 500;
      line-height: 90px;
      letter-spacing: -0.05em;
      span{
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
      }
    }
  }
  .view-form-icon-con{
    height: 21px;
    width: 23px;
    border-radius: 4px;
    background: #d8dadb2e;
    text-align: center;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .bg-green {
    
  }
  .bg-orange {
    background: linear-gradient(103.11deg, #FAA41A 54.33%, #F49700 100.85%);
  }
  .bg-blue {
    background: linear-gradient(103.41deg, #28B2DC 34.91%, #00A0D1 97.36%);
  }

  .rating-bar-con {
    width: 79px;
    height: 6px;
    border-radius: 8px;
    color: #000;
    background-color: #f1f1f1;
  }
  .rating-bar-inner {
    height: 6px;
    border-radius: 8px;
    background: #FAA41A !important;
  }
 
}

.bls-feedback-con {
  .heading {
    text-align: center;
    color: #438D40;
  }
  .after-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    p {
      font-size: 12px;
      span {
        font-family: "SourceSansOpen700";
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .question-answer-con {
    .question{
      font-family: "SourceSansOpen600";
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.10000000149011612px;
      margin-bottom: 5px;
    }
    .answer {
      font-family: "SourceSansOpen400";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.15714286267757416px;
      margin-bottom: 30px;
    }
    .outer-con{
      width: 200px;
      height: 6px;
      border-radius: 8px;
      color: #000;
      background-color: #EDF4ED;
    }
    .inner-con{
      height: 6px;
      border-radius: 8px;
      background: #87B685 !important;
      position: relative;
    }
  }
  @media (max-width: 550px) {
    .after-heading {
      flex-direction: column;
      .name-con{
        margin-bottom: 5px;
      }
    }
  }
}