.heading-back-btn-con {
  .edit-profile-btn{
    border-radius: 6px;
    width: 83px;
    height: 35px;
  }
  .arrow-icon-con {
    cursor: pointer;
  }

}