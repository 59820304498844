.preview-container {
    width: 150px;
    height: 95px;
    position: relative;
    padding: 5px;

    & .cross-button {
        width: 15px;
        height: 15px;
        background-color: white;
        position: absolute;
        right: 10px;
        top: 10px;
        border-radius: 50px;
    }

    & .edit-button {
        width: 33px;
        height: 33px;
        position: absolute;
        right: 5px;
        top: 6px;
    }

    & .preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
}
