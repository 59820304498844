//
// Google font - Poppins
//

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
@font-face {
  font-family: LexendDeca;
  src: url(../../../fonts/LexendDeca-VariableFont_wght.ttf);
}
@font-face {
  font-family: SourceSansOpen400;
  src: url(../../../fonts/SourceSansPro-Semibold.ttf);
}
@font-face {
  font-family: SourceSansOpen600;
  src: url(../../../fonts/SourceSansPro-Bold.ttf);
}

@font-face {
  font-family: SourceSansOpen700;
  src: url(../../../fonts/SourceSansPro-Black.ttf);
}