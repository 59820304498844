.search-input-con{

  .search-icon {
    left: 12px;
    width: fit-content;
  }
  .search-input-field {
    padding-left: 30px;
  }
  .search-text-con{
    width: 400px;
    margin-left: auto;
  }
  @media (max-width: 1350px) {
    .search-text-con{
      width: 300px;
    }
  }
  @media (max-width: 700px) {
    .search-text-con{
      width: 100%;
      margin-top: 20px;
    }
  }
}
