.table-pagination-con{
  .pagination {
    .page-item {
      margin-right: 10px;
      .page-link {
        width: 34px;
        height: 32px;
        border-radius: 6px;
        border: 1px solid #D8DADB80;
        padding: 0px;
        font-family: "SourceSansOpen600";
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.15714286267757416px;
        color: #D8DADB;
        span {
          i {
            line-height: 2;
            color: #444444;
          }
        }
      }
    }
    .page-item.indicators {
      .page-link {
        width: 40px;
        height: 32px;
        color: #ffffff;
      }
    }
    .page-item.active {
      .page-link {
        width: 31px;
        height: 32px;
        color: #ffffff;
      }
    }
  }
}