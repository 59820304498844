.otp-input {
    width: 4rem;
    height: 4rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 2px;
    font-size: 1.01562rem;
    text-align: center;
    margin-right: 16px;
    border-width: 1px;
    border-style: solid;
    text-transform: uppercase;
    border-color: #ced4da;
    background-color: white;
}
