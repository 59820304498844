.app-auth-wrapper {
    .auth-heading-con{
        .login-title {
            font-size: 16px;
            font-weight: 500;
        }
    } 
    .bv-forget-wrap {
        .enter-otp-note{
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.15714286267757416px;
        }
    }
    .forgot-pass-link {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
    }
    .auth-wrap-left-content {
        position: relative;
    }
    .auth-wrap-right-content {
        // css here frt right side
    }
    a {
        &:focus {
            text-decoration: underline !important;
        }
    }
    .main-image {
        position: absolute;
        width: 161px;
        height: 234px;
        top: 0px;
        left: 40px;
    }
}

.auth-wrap-right-content {
    // css here frt right side
    height: 100%;
    display: grid;
    // align-items: center;
}

@media only screen and (max-width: 578px) {
    .auth-wrap-right-content {
        margin: 0 3rem;
    }
}
