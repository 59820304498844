.sorting-con {
    color: #000;
    font-family: "SourceSansOpen400";
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    .sorting-dropdown {
        font-family: "SourceSansOpen400";
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.2px;
        color: #000000;
        border: 1px solid #438D40;
        background-size: 12px 12px;
    }
    .sorting-dropdown:hover option:hover {
        background-color: #e0e0e0; /* Change the background color for hover */
        color: #333; /* Change the text color for hover */
    }
}
